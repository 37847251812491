import React from "react";
import { getValueByPath } from "ClientService/TableColumnUtility";
import {Tag} from "antd";

export const getProcessStatementType =  ( path = ''  ) => {

    return  {
        key: 'type',
        title: 'Art der Anfrage',
        width: 150,
        searchable: false,
        sortable: false,
        render: (value, record) => {
            const processStatement = getValueByPath(record, path);

            if(!!processStatement.isCareProviderResponse) {
                return <Tag color="blue">Mitteilung</Tag>
            }
            if(!!processStatement.question) {
                return <Tag color="orange">Freitext</Tag>
            }
            const { poolRequest } = processStatement;
            if(!poolRequest) {
                if(processStatement.allowFreetext) {
                    return <Tag color="orange">Freitext</Tag>
                }
                return 'Error: No PoolRequest-Entry found!';
            }

            let poolRequestTitle = poolRequest.title || <i>-- kein Titel --</i>;
            if(poolRequestTitle.length > 30) {
                poolRequestTitle = `${ poolRequestTitle.substr(0, 25)} ...`;
            }
            return (
                <React.Fragment>
                    <Tag style={{ marginBottom: '6px' }} color="cyan">Vorlage ({ poolRequestTitle  })</Tag>
                    { (poolRequest.type === 'single') ?
                        <Tag color="blue">Einfachauswahl</Tag> :
                        <Tag color="geekblue">Mehrfachauswahl</Tag>
                    }
                </React.Fragment>
            );
        }
    };

}