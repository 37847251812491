import {
  deleteStatementTemplateSuccess,
  deleteStatementTemplateFailure,
  showNotification,
  DELETE_STATEMENT_TEMPLATE_REQUEST
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

export const deleteStatementTemplateEpic = (action$, store$, { StatementTemplateRepository }) => {
    return action$.pipe(
        ofType(DELETE_STATEMENT_TEMPLATE_REQUEST),
        mergeMap(action => from(StatementTemplateRepository.remove( action.payload.id ))
            .pipe(
                mergeMap(() => {
                    return from([
                        showNotification(`Der Textbaustein wurde erfolgreich gelöscht.`),
                        deleteStatementTemplateSuccess(action.payload.id),
                    ]);
                }),
                catchError( error => {
                    return from([
                        deleteStatementTemplateFailure(error),
                    ])
                })
            )
        ),
    );
}
