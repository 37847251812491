export const getValueByPath = (state = {}, property = '') => {
    if( `${property}`.trim() === '') {
        return state;
    }
    if (property.match(/\./)){
        const properties = property.split('.');
        const count = properties.length;
        let returnValue = state;
        for(let i = 0; i < count; i++){
            if(!returnValue.hasOwnProperty(properties[i])){
                return null;
            }
            returnValue = returnValue[properties[i]];
        }
        return returnValue;
    } else if (!state.hasOwnProperty(property)) {
        return null;
    } else {
        return state[property];
    }
}