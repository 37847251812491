export const FIND_ENCRYPT_PSEUDONYMS_SUCCESS = 'FIND_ENCRYPT_PSEUDONYMS_SUCCESS';

export const findEncryptPseudonymsSuccess = ({ encryptPseudonyms, request, response }) => ({
    type: FIND_ENCRYPT_PSEUDONYMS_SUCCESS,
    payload: { encryptPseudonyms },
    meta: {
        request,
        response,
    }
});
