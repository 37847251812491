import {
  FIND_ENCRYPT_PSEUDONYMS_REQUEST,
  FIND_SINGLE_ENCRYPT_PSEUDONYMS_REQUEST,
}                        from 'Redux/Action';
import { ArrayContains } from 'ClientService';

export const isEncryptPseudonymLoading = (state) =>
  ArrayContains(state.encryptPseudonym.openRequests, [
    FIND_ENCRYPT_PSEUDONYMS_REQUEST,
    FIND_SINGLE_ENCRYPT_PSEUDONYMS_REQUEST,
  ]);
