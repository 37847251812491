import {
  FIND_ENCRYPT_PSEUDONYMS_REQUEST,
  findEncryptPseudonymsSuccess,
  findEncryptPseudonymsFailure
}                   from 'Redux/Action';
import { ofType }   from 'redux-observable';
import { from }     from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function findEncryptPseudonymsEpic(action$, store$, { EncryptPseudonymRepository }) {
  return action$.pipe(
    ofType(FIND_ENCRYPT_PSEUDONYMS_REQUEST),
    mergeMap(action => from(
      EncryptPseudonymRepository.findAll(action.payload)
        .then(({ encryptPseudonyms, meta }) => findEncryptPseudonymsSuccess({
          encryptPseudonyms,
          response: meta,
          request: action
        }))
        .catch(error => findEncryptPseudonymsFailure({ error, request: action })),
    )),
  );
}
