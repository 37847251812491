import * as User                     from './User';
import * as File                     from './File';
import * as Employee                 from './Employee';
import * as EmployeeRole             from './EmployeeRole';
import * as CareProvider             from './CareProvider';
import * as Document                 from './Document';
import * as Module                   from './Module';
import * as General                  from './General';
import * as Result                   from './Result';
import * as PoolRequest              from './PoolRequest';
import * as StatementProcess         from './StatementProcess';
import * as Pseudonym                from './Pseudonym';
import * as TargetActual             from './TargetActual';
import * as StatementTemplate        from './StatementTemplate';
import * as ResultRating             from './ResultRating';
import * as Report                   from './Report';
import * as Edok                     from './Edok';
import * as Chat                     from './Chat';
import * as MailTemplate             from './MailTemplate';
import * as ProcessStatementQuestion from './ProcessStatementQuestion';
import * as EncryptPseudonym         from './EncryptPseudonym';

export * from './User';
export * from './File';
export * from './Employee';
export * from './EmployeeRole';
export * from './CareProvider';
export * from './Document';
export * from './Module';
export * from './General';
export * from './Result';
export * from './PoolRequest';
export * from './StatementProcess';
export * from './Pseudonym';
export * from './TargetActual';
export * from './StatementTemplate';
export * from './ResultRating';
export * from './Report';
export * from './Edok';
export * from './Chat';
export * from './MailTemplate';
export * from './EncryptPseudonym';
export * from './ProcessStatementQuestion';

const bundledActions = {
  ...User,
  ...File,
  ...Employee,
  ...EmployeeRole,
  ...CareProvider,
  ...Module,
  ...General,
  ...Document,
  ...Result,
  ...PoolRequest,
  ...StatementProcess,
  ...Pseudonym,
  ...TargetActual,
  ...StatementTemplate,
  ...ResultRating,
  ...Report,
  ...Edok,
  ...Chat,
  ...MailTemplate,
  ...ProcessStatementQuestion,
  ...EncryptPseudonym,
};

const errorRegEx                  = RegExp('FAILURE');
export const errorActionConstants = Object.keys(bundledActions)
  .filter(element => (typeof element === 'string' || element instanceof String))
  .filter(actionConstant => errorRegEx.test(actionConstant));
