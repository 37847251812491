const getInitialState = () => ({
    openRequests: [],
    failedRequests: [],
    updatedAt: 0,
    lastActiveProcessStatement: null,
    processStatements: {
        entries: {},
        maxCount: 0,
    },
    targetActual: null,
    lastDeadlineDates: {
        toReminderDate: null,
        toAnswerDate: null,
        toSubsequentDeliveryDate: null,
    },
    processStatementDetailResults: null,
    poolRequestInboxCount: 0,
    answeredInboxCount: 0,
    lastInteractiveGuid: null,
});

export default {
    getInitialState
};
