import BaseRepository   from '../Base/BaseRepository';
import Result           from 'Domain/Model/Result/Result';

class ResultRepository extends BaseRepository {

    checkResultRatingMail(data) {
        return this.makeRequest({
            path: `/result/check-send-mail/rating-results`,
            data: data,
            type: 'GET',
            callback: ({ allowToSendMail }) => allowToSendMail
        });
    };

    sendResultRatingMail(data) {
        return this.makeRequest({
            path: `/result/send-mail/rating-results`,
            data: data,
            type: 'POST',
            callback: ({ allowToSendMail }) => allowToSendMail
        });
    };

    findRefResultsById(id) {
        return this.makeRequest({
            path: `/result/${id}/ref-results`,
            type: 'GET',
            callback: (responseObject) => ({
                results: responseObject.results.map(result => new Result(result)),
                targetActual: responseObject.targetActual,
            })
        });
    }


    uploadResults(data) {
        return this.makeRequest({
            path: `/${this.baseRoute}/upload`,
            data: data,
            type: 'POST',
            callback: () => { return true }
        });
    };

    updateComment( id = null, data ) {
        return this.makeRequest({
            path: `/${this.baseRoute}/comment/${id}`,
            data: data,
            type: 'PATCH',
            callback: this.initializeSingleModel
        });
    }

    uploadNoticeResults(data) {
        return this.makeRequest({
            path: `/${this.baseRoute}/notice/upload`,
            data: data,
            type: 'POST',
            callback: () => { return true }
        });
    };

    updateRating(resultIds, updateData) {
        return this.makeRequest({
            path: `/${this.baseRoute}/rating`,
            data: {
                resultIds,
                ...updateData,
            },
            type: 'PATCH',
        });
    }

    updateQsebData(results) {
        return this.makeRequest({
            path: `/${this.baseRoute}/qseb`,
            data: {
                results,
            },
            type: 'PATCH',
        });
    }
    findAllEvalYears (filterSettings = {}) {
        return this.setFilterSettings(filterSettings).makeRequest({
            path: `/${this.baseRoute}/filter-vars/eval-years`,
            data: this.getFilterSettings(),
            type: 'GET',
            callback: ( response ) => {
                return response.evalYears;
            }
        });
    };

    findAllSpecYears (filterSettings = {}) {
        return this.setFilterSettings(filterSettings).makeRequest({
            path: `/${this.baseRoute}/filter-vars/spec-years`,
            data: this.getFilterSettings(),
            type: 'GET',
            callback: ( response ) => {
                return response.specYears;
            }
        });
    };
}

export default new ResultRepository({
    model: Result,
    baseRoute: 'result',
    singular: 'result',
    plural: 'results',
});
