export const FIND_ENCRYPT_PSEUDONYMS_FAILURE = 'FIND_ENCRYPT_PSEUDONYMS_FAILURE';

export const findEncryptPseudonymsFailure = ({ error, request }) => ({
    type: FIND_ENCRYPT_PSEUDONYMS_FAILURE,
    payload: error,
    error: true,
    meta: {
        request
    }
});
