import { combineEpics } from 'redux-observable';

import { findDocumentsEpic }    from './FindDocuments/FindDocumentsEpic';
import { findDocumentEpic }     from './FindDocument/FindDocumentEpic';
import { deleteDocumentEpic }   from './DeleteDocument/DeleteDocumentEpic';
import { deleteDocumentsEpic }  from './DeleteDocuments/DeleteDocumentsEpic';
import { downloadDocumentEpic } from './DownloadDocument/DownloadDocumentEpic';
import { uploadDocumentEpic }   from './UploadDocument/UploadDocumentEpic';


export default combineEpics(
  findDocumentsEpic,
  findDocumentEpic,
  deleteDocumentEpic,
  deleteDocumentsEpic,
  downloadDocumentEpic,
  uploadDocumentEpic,
);
