import { combineEpics } from 'redux-observable';

import { createStatementTemplateEpic }            from './CreateStatementTemplate/CreateStatementTemplateEpic';
import { deleteStatementTemplateEpic }            from './DeleteStatementTemplate/DeleteStatementTemplateEpic';
import { findIndicatorIdsEpic }                   from './FindIndicatorIds/FindIndicatorIdsEpic';
import { findStatementTemplateByIndicatorIdEpic } from './FindStatementTemplateByIndicatorId/FindStatementTemplateByIndicatorIdEpic';
import { updateStatementTemplateEpic }            from './UpdateStatementTemplate/UpdateStatementTemplateEpic';
import { createStatementTemplatePlaceholderEpic } from './CreateStatementTemplatePlaceholder/CreateStatementTemplatePlaceholderEpic';
import { findStatementTemplatePlaceholdersEpic }  from './FindStatementTemplatePlaceholders/FindStatementTemplatePlaceholdersEpic';


export default combineEpics(
  createStatementTemplateEpic,
  deleteStatementTemplateEpic,
  findIndicatorIdsEpic,
  findStatementTemplateByIndicatorIdEpic,
  updateStatementTemplateEpic,
  createStatementTemplatePlaceholderEpic,
  findStatementTemplatePlaceholdersEpic,
);
