import Result     from "Domain/Model/Result/Result";
import PoolRequest from "Domain/Model/PoolRequest/PoolRequest";

export default class ProcessStatement {

    constructor( data ) {

        this.id                       = data.id;
        this.result                   = !!data.result ? new Result(data.result) : null;
        this.question                 = data.question;
        this.answer                   = data.answer;
        this.commentFk                = data.commentFk;
        this.checked                  = data.checked || [];
        this.additionalText           = data.additionalText;
        this.hasAttachments           = data.hasAttachments;
        this.allowFreetext            = data.allowFreetext;
        this.attachments              = data.attachments;
        this.answerDate               = data.answerDate;
        this.submitDate               = data.submitDate;
        this.poolRequest              = !!data.poolRequest ? new PoolRequest(data.poolRequest) : null;
        this.requestSendDate          = data.requestSendDate;
        this.answerSendDate           = data.answerSendDate;
        this.requestDate              = data.requestDate;
        this.readDate                 = data.readDate ? new Date(data.readDate) : null;
        this.reminderDate             = data.reminderDate;
        this.requestInbox             = data.requestInbox;
        this.careProvider             = data.careProvider;
        this.answered                 = this.isAnswered();
        this.answerInbox              = data.answerInbox;
        this.lastDownloadDate         = data.lastDownloadDate;
        this.allowAnswerFreetext      = data.allowAnswerFreetext;
        this.allowAttachments         = data.allowAttachments;
        this.requestAttachments       = data.requestAttachments || [];
        this.isCareProviderResponse   = data.isCareProviderResponse;
        this.toReminderDate           = data.toReminderDate ? new Date(data.toReminderDate) : null;
        this.toAnswerDate             = data.toAnswerDate ? new Date(data.toAnswerDate) : null;
        this.toSubsequentDeliveryDate = data.toSubsequentDeliveryDate ? new Date(data.toSubsequentDeliveryDate) : null;
        this.notAnswered              = data.notAnswered;
        this.formatQuestion           = data.formatQuestion;
        this.formatAnswer             = data.formatAnswer;
        this.formatSubmitInformation  = data.formatSubmitInformation;
        this.formatAdditionalText     = data.formatAdditionalText;
        this.formatCommentFk          = data.formatCommentFk;
        this.conspicuousOps           = data.conspicuousOps;
        this.comments                 = data.comments || [];
        this.showAllComments          = data.showAllComments;
    }

    isAnswered = () => {
        return (!!this.answerDate && (this.answer !== null || this.checked.length > 0 || this.hasAttachments > 0));
    }
}
