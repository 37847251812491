import {
  UPDATE_PROCESS_STATEMENT_COMMENT_REQUEST,
  updateProcessStatementCommentSuccess,
  updateProcessStatementCommentFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function updateProcessStatementCommentEpic(action$, store$, { ProcessStatementRepository }) {
    return action$.pipe(
        ofType(UPDATE_PROCESS_STATEMENT_COMMENT_REQUEST),
        mergeMap( action => from(ProcessStatementRepository.updateComment(action.payload.processStatementId, action.payload.processStatementComment ))
            .pipe(
                mergeMap( statement => {
                    message.success('Stellungnahme wurde erfolgreich gespeichert.');
                    return from ([
                      updateProcessStatementCommentSuccess(statement),
                    ]);
                }),
                catchError( error => {
                    return from([
                      updateProcessStatementCommentFailure(error),
                    ])
                })
            )
        ),
    );
}
