import BaseRepository from '../Base/BaseRepository';
import SingleEncryptPseudonym from 'Domain/Model/SingleEncryptPseudonym/SingleEncryptPseudonym';


class EncryptSinglePseudonymRepository extends BaseRepository {

}

export default new EncryptSinglePseudonymRepository({
    model: SingleEncryptPseudonym,
    baseRoute: 'encrypt-pseudonym/single-encrypts',
    singular: 'encryptPseudonym',
    plural: 'encryptPseudonyms',
});
