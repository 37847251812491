import { globalReducer }                   from './Global/GlobalReducer';
import { userReducer }                     from './User/UserReducer';
import { fileReducer }                     from './File/FileReducer';
import { employeeReducer }                 from './Employee/EmployeeReducer';
import { employeeRoleReducer }             from './EmployeeRole/EmployeeRoleReducer';
import { careProviderReducer }             from './CareProvider/CareProviderReducer';
import { documentReducer }                 from './Document/DocumentReducer';
import { persistStoreReducer }             from './PersistStore/PersistStoreReducer';
import { moduleReducer }                   from './Module/ModuleReducer';
import { resultReducer }                   from './Result/ResultReducer';
import { poolRequestReducer }              from './PoolRequest/PoolRequestReducer';
import { processStatementReducer }         from './ProcessStatement/ProcessStatementReducer';
import { pseudonymReducer }                from './Pseudonym/PseudonymReducer';
import { targetActualReducer }             from './TargetActual/TargetActualReducer';
import { statementTemplateReducer }        from './StatementTemplate/StatementTemplateReducer';
import { tableReducer }                    from './Table/TableReducer';
import { resultRatingReducer }             from './ResultRating/ResultRatingReducer';
import { reportReducer }                   from './Report/ReportReducer';
import { edokReducer }                     from './Edok/EdokReducer';
import { chatReducer }                     from './Chat/ChatReducer';
import { mailTemplateReducer }             from './MailTemplate/MailTemplateReducer';
import { tempStoreReducer }                from './TempStore/TempStoreReducer';
import { processStatementQuestionReducer } from './ProcessStatementQuestion/ProcessStatementQuestionReducer';
import { encryptPseudonymReducer }         from './EncryptPseudonym/EncryptPseudonymReducer';

export default {
  global                  : globalReducer,
  user                    : userReducer,
  file                    : fileReducer,
  employee                : employeeReducer,
  employeeRole            : employeeRoleReducer,
  careProvider            : careProviderReducer,
  document                : documentReducer,
  persistStore            : persistStoreReducer,
  module                  : moduleReducer,
  result                  : resultReducer,
  poolRequest             : poolRequestReducer,
  processStatement        : processStatementReducer,
  pseudonym               : pseudonymReducer,
  targetActual            : targetActualReducer,
  statementTemplate       : statementTemplateReducer,
  table                   : tableReducer,
  resultRating            : resultRatingReducer,
  report                  : reportReducer,
  edok                    : edokReducer,
  chat                    : chatReducer,
  mailTemplate            : mailTemplateReducer,
  tempStore               : tempStoreReducer,
  processStatementQuestion: processStatementQuestionReducer,
  encryptPseudonym        : encryptPseudonymReducer,
};
