export const FIND_SINGLE_ENCRYPT_PSEUDONYMS_SUCCESS = 'FIND_SINGLE_ENCRYPT_PSEUDONYMS_SUCCESS';

export const findSingleEncryptPseudonymsSuccess = ({ encryptPseudonyms, request, response }) => ({
    type: FIND_SINGLE_ENCRYPT_PSEUDONYMS_SUCCESS,
    payload: { encryptPseudonyms },
    meta: {
        request,
        response,
    }
});
