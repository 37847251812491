import {
  FIND_ENCRYPT_PSEUDONYMS_REQUEST,
  FIND_ENCRYPT_PSEUDONYMS_SUCCESS,
  FIND_ENCRYPT_PSEUDONYMS_FAILURE,

  FIND_SINGLE_ENCRYPT_PSEUDONYMS_REQUEST,
  FIND_SINGLE_ENCRYPT_PSEUDONYMS_SUCCESS,
  FIND_SINGLE_ENCRYPT_PSEUDONYMS_FAILURE,

  LOGOUT_USER,

  DISABLE_USER_SUCCESS,
  AUTHENTICATE_FAILURE,

}                            from 'Redux/Action';
import EncryptPseudonymState from 'Redux/Store/EncryptPseudonym/EncryptPseudonymState';
import {
  removeOpenRequest,
  removeFailedRequest
}                            from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = EncryptPseudonymState.getInitialState();

export const encryptPseudonymReducer = (state = initialState, action) => {

  switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
    case DISABLE_USER_SUCCESS :
      return {
        ...initialState,
      };


    case FIND_ENCRYPT_PSEUDONYMS_REQUEST:
    case FIND_SINGLE_ENCRYPT_PSEUDONYMS_REQUEST:
      return {
        ...state,
        lastInteractiveId: null,
        openRequests     : [ ...state.openRequests, action.type ],
        failedRequests   : removeFailedRequest(action, state),
        updatedAt        : Date.now(),
      };

    case FIND_ENCRYPT_PSEUDONYMS_SUCCESS:
      return {
        ...state,
        openRequests     : removeOpenRequest(action, state),
        encryptPseudonyms: {
          entries : action.payload.encryptPseudonyms,
          maxCount: (action.meta) ? action.meta.response.count : state.encryptPseudonyms.maxCount,
        },
        updatedAt        : Date.now(),
      };

    case FIND_SINGLE_ENCRYPT_PSEUDONYMS_SUCCESS:
      return {
        ...state,
        openRequests     : removeOpenRequest(action, state),
        encryptSinglePseudonyms: {
          entries : action.payload.encryptPseudonyms,
          maxCount: (action.meta) ? action.meta.response.count : state.encryptPseudonyms.maxCount,
        },
        updatedAt        : Date.now(),
      };

    case FIND_ENCRYPT_PSEUDONYMS_FAILURE:
    case FIND_SINGLE_ENCRYPT_PSEUDONYMS_FAILURE:
      return {
        ...state,
        lastInteractiveId: null,
        openRequests     : removeOpenRequest(action, state),
        failedRequests   : [ ...state.failedRequests, action.type ],
        updatedAt        : Date.now(),
      };


    default:
      return state;
  }
};
