import React from "react";
import { getValueByPath } from "ClientService/TableColumnUtility";

export const getCareProviderNumber =  ( path = 'careProvider'  ) => {

    return {
        key: 'identNumber',
        title: 'Identifikationsnr.',
        width: 200,
        searchFieldRelation: '`CareProvider`.`identity`',
        sortable: false,
        render: (value, record) => {
            const careProvider = getValueByPath(record, path);
            if(!careProvider) {
                return (<i>- nicht zugeordnet -</i>);
            }
            return (
                <React.Fragment>
                    { record.careProvider.identNumber }
                    {(record.careProvider.destinationKey) ? <React.Fragment><br />{ `(${record.careProvider.destinationKey})` }</React.Fragment> : '' }
                </React.Fragment>
            )
        }


    };
}