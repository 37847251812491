import {
  PERSIST_PROCESS_STATEMENT_QUESTIONS_REQUEST,
  persistProcessStatementQuestionsSuccess,
  persistProcessStatementQuestionsFailure,
}                               from 'Redux/Action';
import { ofType }               from 'redux-observable';
import { from }                 from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message }              from 'antd';

export function persistProcessStatementQuestionsEpic(action$, store$, { ProcessStatementQuestionRepository }) {
  return action$.pipe(
    ofType(PERSIST_PROCESS_STATEMENT_QUESTIONS_REQUEST),
    mergeMap(action => from(ProcessStatementQuestionRepository.persist(action.payload.questions))
      .pipe(
        mergeMap(({ questions }) => {
          message.success('Die Änderungen wurde erfolgreich übernommen.');
          return from([
            persistProcessStatementQuestionsSuccess({ questions }),
          ]);
        }),
        catchError(error => {
          return from([
            persistProcessStatementQuestionsFailure(error),
          ]);
        })
      )
    ),
  );
}
