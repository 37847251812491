export default class ProcessStatementQuestion {

  constructor(data) {

    this.id        = data.id;
    this.createdAt = data.createdAt;
    this.question  = data.question;
    this.sorting   = data.sorting;

  }

}
