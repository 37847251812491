import Employee  from 'Domain/Model/Employee/Employee';

export default class EncryptPseudonym {

  constructor(data) {

    this.id          = data.id || 'new';
    this.createdAt   = new Date(data.createdAt * 1000);
    this.module      = data.module || '';
    this.year        = data.year || '';
    this.employee    = data.employee && new Employee(data.employee) || null;
    this.forAllLagGs = (data.releaseDate) ? new Date(data.releaseDate) : null;

  }

}
