import UserRepository                     from './User/UserRepository';
import FileRepository                     from './File/FileRepository';
import EmployeeRepository                 from './Employee/EmployeeRepository';
import EmployeeRoleRepository             from './EmployeeRole/EmployeeRoleRepository';
import CareProviderRepository             from './CareProvider/CareProviderRepository';
import DocumentRepository                 from './Document/DocumentRepository';
import SettingsRepository                 from './Settings/SettingsRepository';
import ModuleRepository                   from './Module/ModuleRepository';
import ResultRepository                   from './Result/ResultRepository';
import PoolRequestRepository              from './PoolRequest/PoolRequestRepository';
import ProcessStatementRepository         from './ProcessStatement/ProcessStatementRepository';
import PseudonymRepository                from './Pseudonym/PseudonymRepository';
import TargetActualRepository             from './TargetActual/TargetActualRepository';
import StatementTemplateRepository        from './StatementTemplate/StatementTemplateRepository';
import ResultRatingRepository             from './ResultRating/ResultRatingRepository';
import ReportRepository                   from './Report/ReportRepository';
import EdokRepository                     from './Edok/EdokRepository';
import ChatRepository                     from './Chat/ChatRepository';
import MailTemplateRepository             from './MailTemplate/MailTemplateRepository';
import ProcessStatementQuestionRepository from './ProcessStatementQuestion/ProcessStatementQuestionRepository';
import EncryptPseudonymRepository         from './EncryptPseudonym/EncryptPseudonymRepository';
import EncryptSinglePseudonymRepository   from './EncryptSinglePseudonym/EncryptSinglePseudonymRepository';

export default {
  UserRepository,
  FileRepository,
  EmployeeRepository,
  EmployeeRoleRepository,
  CareProviderRepository,
  DocumentRepository,
  SettingsRepository,
  ModuleRepository,
  ResultRepository,
  PoolRequestRepository,
  ProcessStatementRepository,
  PseudonymRepository,
  TargetActualRepository,
  StatementTemplateRepository,
  ResultRatingRepository,
  ReportRepository,
  EdokRepository,
  ChatRepository,
  MailTemplateRepository,
  ProcessStatementQuestionRepository,
  EncryptPseudonymRepository,
  EncryptSinglePseudonymRepository,
};
