import { ADM_KH }                       from 'AppConfig/Config';
import { getModules, userContainsRole } from 'Redux/Query';

export const getActiveModules = (state, employee = null) => {
    let employeeModules = getModules(state).map(module => module.id);
    const isAdminKh = userContainsRole(state)([ADM_KH]);

    if(isAdminKh && !employee?.modules?.length ) {
        return [];
    }

    if(employee !== null && !!employee.modules && employee.modules.length > 0) {
        employeeModules = employee.modules.map( module => module.id );
    }

    return employeeModules;
};
