import {
    DELETE_DOCUMENTS_REQUEST,
    deleteDocumentsSuccess,
    deleteDocumentsFailure,
    showNotification
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

export const deleteDocumentsEpic = (action$, store$, { DocumentRepository }) => {
    return action$.pipe(
        ofType(DELETE_DOCUMENTS_REQUEST),
        mergeMap(action => from(DocumentRepository.bulkRemove( action.payload.ids ))
            .pipe(
                mergeMap(() => {
                    return from([
                        showNotification(`Die Dokumente wurde erfolgreich gelöscht.`),
                        deleteDocumentsSuccess(action.payload.ids),
                    ]);
                }),
                catchError( error => {
                    return from([
                        deleteDocumentsFailure(error),
                    ])
                })
            )
        ),
    );
}
