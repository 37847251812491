import BaseRepository           from '../Base/BaseRepository';
import ProcessStatementQuestion from 'Domain/Model/ProcessStatementQuestion/ProcessStatementQuestion';

class ProcessStatementQuestionRepository extends BaseRepository {

  findAll() {
    return this.makeRequest({
      path: `/${this.baseRoute}`,
      type: 'GET'
    });
  }

  persist(questions) {
    return this.makeRequest({
      path: `/${this.baseRoute}`,
      data: {
        questions
      },
      type: 'PATCH'
    });
  }

}

export default new ProcessStatementQuestionRepository({
  model    : ProcessStatementQuestion,
  baseRoute: 'process-statement-question',
  singular : 'question',
  plural   : 'questions',
});
