export const FIND_ENCRYPT_PSEUDONYMS_REQUEST = 'FIND_ENCRYPT_PSEUDONYMS_REQUEST';

export const findEncryptPseudonymsRequest = ({
     filter = [],
     sorting = { field: 'createdAt', order: 'ASC' },
     pagination = { page: 1, take: 50 },
 }) => ({
    type: FIND_ENCRYPT_PSEUDONYMS_REQUEST,
    payload: { filter, sorting, pagination },
    meta: {
        sorting,
        pagination,
    }
});
