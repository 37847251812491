import React from 'react';
import Button from './Button.style';
import { Tooltip } from 'antd';

export default class extends React.Component {

    handleClick = (e) => {
        if(this.props.hasOwnProperty('subject')) {
            this.props.subject.next();
        }
        if(this.props.hasOwnProperty('onClick') && typeof this.props.onClick === 'function') {
            this.props.onClick(e);
        }
    };

    renderButton() {
        return (
            <Button
                type={ this.props.type || 'primary' }
                { ...this.props }
                className={ this.props.className }
                title={ '' }
                onClick={this.handleClick}
            >{ this.props.children || this.props.label }</Button>
        );
    }

    render() {
        if(this.props.title) {
            return (
                <Tooltip title={this.props.title} >
                    { this.renderButton() }
                </Tooltip>
            );
        }
        return this.renderButton();
    }

}
