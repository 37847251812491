import {
  FIND_PROCESS_STATEMENT_QUESTIONS_REQUEST,
  FIND_PROCESS_STATEMENT_QUESTIONS_SUCCESS,
  FIND_PROCESS_STATEMENT_QUESTIONS_FAILURE,

  PERSIST_PROCESS_STATEMENT_QUESTIONS_REQUEST,
  PERSIST_PROCESS_STATEMENT_QUESTIONS_SUCCESS,
  PERSIST_PROCESS_STATEMENT_QUESTIONS_FAILURE,

  LOGOUT_USER,
  DISABLE_USER_SUCCESS,
  AUTHENTICATE_FAILURE,
}                                    from 'Redux/Action';
import ProcessStatementQuestionState from 'Redux/Store/ProcessStatementQuestion/ProcessStatementQuestionState';
import {
  removeOpenRequest,
  removeFailedRequest,
}                                    from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = ProcessStatementQuestionState.getInitialState();

export const processStatementQuestionReducer = (state = initialState, action) => {

  switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
    case DISABLE_USER_SUCCESS :
      return {
        ...initialState,
      };

    case FIND_PROCESS_STATEMENT_QUESTIONS_REQUEST:
    case PERSIST_PROCESS_STATEMENT_QUESTIONS_REQUEST:
      return {
        ...state,
        openRequests  : [ ...state.openRequests, action.type ],
        failedRequests: removeFailedRequest(action, state),
        updatedAt     : Date.now(),
      };

    case FIND_PROCESS_STATEMENT_QUESTIONS_SUCCESS:
    case PERSIST_PROCESS_STATEMENT_QUESTIONS_SUCCESS:
      return {
        ...state,
        openRequests: removeOpenRequest(action, state),
        updatedAt   : Date.now(),
        questions   : action.payload.questions,
      };


    case FIND_PROCESS_STATEMENT_QUESTIONS_FAILURE:
    case PERSIST_PROCESS_STATEMENT_QUESTIONS_FAILURE:
      return {
        ...state,
        openRequests  : removeOpenRequest(action, state),
        failedRequests: [ ...state.failedRequests, action.type ],
        updatedAt     : Date.now(),
      };


    default:
      return state;
  }
};
