import {
    FIND_DOCUMENT_REQUEST,
    FIND_DOCUMENT_SUCCESS,
    FIND_DOCUMENT_FAILURE,

    FIND_DOCUMENTS_REQUEST,
    FIND_DOCUMENTS_SUCCESS,
    FIND_DOCUMENTS_FAILURE,

    DELETE_DOCUMENT_REQUEST,
    DELETE_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_FAILURE,

    DELETE_DOCUMENTS_REQUEST,
    DELETE_DOCUMENTS_SUCCESS,
    DELETE_DOCUMENTS_FAILURE,

    UPLOAD_DOCUMENT_REQUEST,
    UPLOAD_DOCUMENT_SUCCESS,
    UPLOAD_DOCUMENT_FAILURE,

    DOWNLOAD_DOCUMENT_REQUEST,
    DOWNLOAD_DOCUMENT_FAILURE,

    LOGOUT_USER,
    REFRESH_DOCUMENT_STATE,
    DISABLE_USER_SUCCESS,
    AUTHENTICATE_FAILURE,

} from 'Redux/Action';
import DocumentState from 'Redux/Store/Document/DocumentState';
import { removeOpenRequest, removeFailedRequest, addSingleObjectToReducer, getSingleObject } from 'ClientService/ReducerUtil/ReducerUtil';

const initialState = DocumentState.getInitialState();

export const documentReducer = (state = initialState, action) => {

    switch (action.type) {

    case AUTHENTICATE_FAILURE :
    case LOGOUT_USER :
    case DISABLE_USER_SUCCESS :
        return {
            ...initialState,
        };

    case REFRESH_DOCUMENT_STATE:
        return {
            ...state,
            updatedAt: Date.now(),
        };

    case UPLOAD_DOCUMENT_REQUEST:
    case DELETE_DOCUMENT_REQUEST:
    case DELETE_DOCUMENTS_REQUEST:
    case FIND_DOCUMENT_REQUEST:
    case FIND_DOCUMENTS_REQUEST:
        return {
            ...state,
            lastInteractiveId: null,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };

    case DOWNLOAD_DOCUMENT_REQUEST:
        return {
            ...state,
            openRequests: [...state.openRequests, action.type],
            failedRequests: removeFailedRequest(action, state),
            updatedAt: Date.now(),
        };


    case DELETE_DOCUMENT_SUCCESS:
        const documentEntries = { ...state.documents.entries };
        if(documentEntries[action.payload.id]) {
            delete documentEntries[action.payload.id];
        }

        return {
            ...state,
            lastInteractiveId: action.payload.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            documents: {
                maxCount: state.documents.maxCount - 1,
                entries: documentEntries
            },
        };

    case DELETE_DOCUMENTS_SUCCESS: {
        const documentEntries = { ...state.documents.entries };
        action.payload.ids.forEach(id => {
            if (documentEntries[id]) {
                delete documentEntries[id];
            }
        });

        return {
            ...state,
            openRequests     : removeOpenRequest(action, state),
            updatedAt        : Date.now(),
            documents        : {
                maxCount: state.documents.maxCount - action.payload.ids.length,
                entries : documentEntries
            },
        };
    }

    case UPLOAD_DOCUMENT_SUCCESS:
    case FIND_DOCUMENT_SUCCESS:
        const document = getSingleObject(action.payload.document);

        return {
            ...state,
            lastInteractiveId: document.id,
            openRequests: removeOpenRequest(action, state),
            updatedAt: Date.now(),
            documents: {
                ...state.documents,
                entries: addSingleObjectToReducer(state.documents.entries, document)
            },
        };

    case FIND_DOCUMENTS_SUCCESS:
        return {
            ...state,
            openRequests: removeOpenRequest(action, state),
            documents: {
                entries: action.payload.documents,
                maxCount: (action.meta) ? action.meta.response.count : state.documents.maxCount,
            },
            updatedAt: Date.now(),
        };

    case UPLOAD_DOCUMENT_FAILURE:
    case FIND_DOCUMENT_FAILURE:
    case FIND_DOCUMENTS_FAILURE:
    case DELETE_DOCUMENT_FAILURE:
    case DELETE_DOCUMENTS_FAILURE:
    case DOWNLOAD_DOCUMENT_FAILURE:
        return {
            ...state,
            lastInteractiveId: null,
            openRequests: removeOpenRequest(action, state),
            failedRequests: [...state.failedRequests, action.type],
            updatedAt: Date.now(),
        };



    default:
        return state;
    }
};
