import {
  SHOW_PROCESS_STATEMENT_COMMENTS_REQUEST,
  showProcessStatementCommentsSuccess,
  showProcessStatementCommentsFailure,
} from 'Redux/Action';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { message } from "antd";

export function showProcessStatementCommentsEpic(action$, store$, { ProcessStatementRepository }) {
    return action$.pipe(
        ofType(SHOW_PROCESS_STATEMENT_COMMENTS_REQUEST),
        mergeMap( action => from(ProcessStatementRepository.showComment(action.payload.processStatementId))
            .pipe(
                mergeMap( statement => {
                    message.success('Stellungnahme-Kommentare wurde erfolgreich freigegeben.');
                    return from ([
                      showProcessStatementCommentsSuccess(statement),
                    ]);
                }),
                catchError( error => {
                    return from([
                      showProcessStatementCommentsFailure(error),
                    ])
                })
            )
        ),
    );
}
