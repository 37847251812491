import { getValueByPath } from 'ClientService/TableColumnUtility';
import ButtonHelpMessages from 'AppConfig/Messages/ButtonHelpMessages';
import TableHelpMessages from 'AppConfig/Messages/TableHelpMessages';

const HelpMessages = {
  button: ButtonHelpMessages,
  table: TableHelpMessages,
}

export const getHelpText = (path, args = null) => {
  const _path = path.split('.');
  const namespace = _path.shift();

  let text = getValueByPath(HelpMessages[namespace], _path.join('.'));

  if(args) {
    Object.keys(args).forEach(key => {
      text = text.replace(new RegExp(`{{${key}}}`, 'g'), args[key]);
    });
  }

  return text;

}
