const getInitialState = () => ({
    openRequests: [],
    failedRequests: [],
    updatedAt: 0,
    questions: [],
    lastInteractiveId: null,
});

export default {
    getInitialState
};
