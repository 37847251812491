import {
  FIND_PROCESS_STATEMENT_QUESTIONS_REQUEST,
  findProcessStatementQuestionsSuccess,
  findProcessStatementQuestionsFailure
}                               from 'Redux/Action';
import { ofType }               from 'redux-observable';
import { from }                 from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

export function findProcessStatementQuestionsEpic(action$, store$, { ProcessStatementQuestionRepository }) {
  return action$.pipe(
    ofType(FIND_PROCESS_STATEMENT_QUESTIONS_REQUEST),
    mergeMap(action => from(ProcessStatementQuestionRepository.findAll())
      .pipe(
        mergeMap(({ questions }) => {
          return from([
            findProcessStatementQuestionsSuccess({ questions }
            ),
          ]);
        }),
        catchError(error => {
          return from([
            findProcessStatementQuestionsFailure(error),
          ]);
        })
      )
    ),
  );
}
