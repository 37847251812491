import Employee     from 'Domain/Model/Employee/Employee';
import CareProvider from 'Domain/Model/CareProvider/CareProvider';

export default class SingleEncryptPseudonym {

  constructor(data) {

    this.id            = data.id || 'new';
    this.createdAt     = new Date(data.createdAt * 1000);
    this.pseudonym     = data.pseudonyms?.[0] || null;
    this.givenIdentity = data.givenIdentity;
    this.employee      = data.employee && new Employee(data.employee) || null;
    this.careProvider  = data.careProvider && new CareProvider(data.careProvider) || null;

  }

}
