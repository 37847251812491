import React from "react";
import { getValueByPath } from "ClientService/TableColumnUtility";
import Link from "Common/Link/Link";
import { linkHandler } from "ClientService";
import { Tag } from "antd";

export const getPseudoNames =  ( path = 'pseudonyms'  ) => {

    return {
        key: 'pseudoName',
        title: 'Pseudonyme',
        width: 200,
        sortable: false,
        searchFieldRelation: '`Pseudonyms`.`key`',
        render: (value, record) => {
            const pseudonyms = getValueByPath(record, path);
            let returnValue = (<i>-- Keine Pseudonyme --</i>);
            if(pseudonyms) {
                returnValue = [];
                pseudonyms.forEach((pseudonym, index) => {
                    returnValue.push(
                        <Link key={`pseudonym${pseudonym.id}_${index}`} to={linkHandler.get('pseudonymDetail', { id: pseudonym.id })}>
                            <Tag color="blue" style={{ marginBottom: 5 }}>{ pseudonym.key }</Tag>
                        </Link>
                    )
                })
            }
            return returnValue
        }
    }
}