import BaseRepository from '../Base/BaseRepository';
import EncryptPseudonym from 'Domain/Model/EncryptPseudonym/EncryptPseudonym';


class EncryptPseudonymRepository extends BaseRepository {

}

export default new EncryptPseudonymRepository({
    model: EncryptPseudonym,
    baseRoute: 'encrypt-pseudonym',
    singular: 'encryptPseudonym',
    plural: 'encryptPseudonyms',
});
