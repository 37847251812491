export const FIND_SINGLE_ENCRYPT_PSEUDONYMS_FAILURE = 'FIND_SINGLE_ENCRYPT_PSEUDONYMS_FAILURE';

export const findSingleEncryptPseudonymsFailure = ({ error, request }) => ({
    type: FIND_SINGLE_ENCRYPT_PSEUDONYMS_FAILURE,
    payload: error,
    error: true,
    meta: {
        request
    }
});
